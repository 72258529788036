import React from "react"
import { useTranslation } from "react-i18next"
import Link from "../../components/element/Link"
import DocsLayout from "../../components/layout/DocsLayout"
import Page from "../../components/page"

const NotFound = ({ pageContext }) => {
  return process.env.ALGOLIA_ONLY_REFRESH !== 'true' && <Page lang={'en'}>
    <DocsLayout sidebar={pageContext.sidebar[pageContext.lang]}>
      <Tradotta />
    </DocsLayout>
  </Page>
}

const Tradotta = () => {
  const [t] = useTranslation("page_404")

  return <section className="container max-w-full text-center mb-3 pb-5 flex flex-col items-center">
    <img src="/images/dog-404.png" className="m-1" style={{ maxWidth: "min(100%,492px)" }} />
    <h1 className="dark:text-white">{t("title")}</h1>
    <p className="mt-1 mb-5 lg:text-big text-default">{t("testo")}</p>
    <div>
      <Link to={t("bottoneHome.link")} button className="sm:mr-3 mb-3 sm:mb-0">{t("bottoneHome.testo")}</Link><br className="sm:hidden" />
      <Link to={t("bottoneAssistenza.link")}>
        <u>{t("bottoneAssistenza.testo")}</u>
      </Link>
    </div>
  </section>
}

export default NotFound